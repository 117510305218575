<template>
  <div>
    <b-container fluid>
      <b-row v-if="track == null" class="mt-5">
        <b-col class="text-center">
          <b-spinner label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </b-container>

    <router-view v-if="track != null" 
      :track="track"
      :albumId="albumId" 
       />
  </div>
</template>

<script>
  export default {
    name: 'TrackDetailContainer',
    data () {
      return {
        track: null,
      }
    },
    props: {
      id: String,
      albumId: String,
    },
    methods: {
    },
    mounted () {
      this.$http.get('tracks/'+this.id, {
          
        })
        .then(response => {
          this.track = response.data
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }
</script>