var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _vm.track == null
            ? _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [_c("b-spinner", { attrs: { label: "Spinning" } })],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.track != null
        ? _c("router-view", {
            attrs: { track: _vm.track, albumId: _vm.albumId }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }